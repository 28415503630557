// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-condorbuilder-js": () => import("./../../../src/pages/condorbuilder.js" /* webpackChunkName: "component---src-pages-condorbuilder-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maxpain-js": () => import("./../../../src/pages/maxpain.js" /* webpackChunkName: "component---src-pages-maxpain-js" */),
  "component---src-pages-optionpricer-js": () => import("./../../../src/pages/optionpricer.js" /* webpackChunkName: "component---src-pages-optionpricer-js" */),
  "component---src-pages-pcratios-js": () => import("./../../../src/pages/pcratios.js" /* webpackChunkName: "component---src-pages-pcratios-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-spreadfinder-js": () => import("./../../../src/pages/spreadfinder.js" /* webpackChunkName: "component---src-pages-spreadfinder-js" */),
  "component---src-pages-tickerreport-js": () => import("./../../../src/pages/tickerreport.js" /* webpackChunkName: "component---src-pages-tickerreport-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

